import React from "react";
import { Text, View } from "@react-pdf/renderer";


export const createTableHeader = (columns) => {
    return (
      <View style={tableRowStyle} fixed>
          <View style={firstTableColHeaderStyle}>
          <Text style={tableCellHeaderStyle}>{columns[0]}</Text>
        </View>
          {columns.slice(1).map(col=><View key={col} style={tableColHeaderStyle}>
          <Text style={tableCellHeaderStyle}>{col}</Text>
        </View>)}
      </View>
    );
  };

export const createTableRow = (columns) => {
    return (
      <View style={tableRowStyle}>
           <View style={firstTableColStyle}>
          <Text style={tableCellStyle}>{columns[0]}</Text>
        </View>
          {columns.slice(1).map((col, i)=> <View key={col+i} style={tableColStyle}>
          <Text style={tableCellStyle}>{col}</Text>
        </View>)}
        
      </View>
    );
  };

//   return (
//     <Document>
//       <Page
//         style={pageStyle}
//         size="A4"
//         orientation="portrait">

//         <View style={tableStyle}>
//           {createTableHeader()}
//           {createTableRow()}
//           {createTableRow()}
//           {createTableRow()}
//           {createTableRow()}
//           {createTableRow()}
//         </View>

//       </Page>
//     </Document>
//   );

// };

const width = 200

const pageStyle = {
  paddingTop: 16,
  paddingHorizontal: 40,
  paddingBottom: 56
};

export const pdfTableStyle = {
  display: "table",
  width: "auto"
};

const tableRowStyle = {
  flexDirection: "row"
};

const firstTableColHeaderStyle = {
//   width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#bdbdbd"
};

const tableColHeaderStyle = {
//   width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const firstTableColStyle = {
//   width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0
};

const tableColStyle = {
//   width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0
};

const tableCellHeaderStyle = {
  width: 12,
  textAlign: "center",
  margin: 4,
  fontSize: 6,
  fontWeight: "bold"
};

const tableCellStyle = {
  width: 12,
  textAlign: "center",
  margin: 4,
  fontSize: 6
};

// export default TableDocument;